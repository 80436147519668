// JavaScriptモジュール
import uajs from './modules/ua.js';

const break_point = 780;

/**
 * メインビジュアル
 */
const setMainVisual = () => {

    if ( $(".js-mainvisual .js-slider").length <= 0 ) { return; }

    const targets = $(".js-mainvisual .js-slider")
    	;

    targets.each(function(){
        const target = $(this)
        ;

        if ( target.is(":visible") ) {
            target.removeClass("pc-only");
            target.removeClass("sp-only");

            target.slick({
                arrows: false,
                dots: true,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 8000,
                speed: 500,
                fade: true,
                pauseOnFocus: false,
                pauseOnHover: false
            });

            setTimeout(function(){
                target.find(".slide-item:first-child").addClass("anim-zoom");
            }, 500)

        } else if ( target.is(":hidden") ) {
            target.remove();
        }
    });
};

/**
 * お品書き　ギャラリー
 */
const setMenuGallery = () => {

    if ( $(".js-gallery").length <= 0 ) { return; }

    const target = $(".js-gallery")
		;

    target.slick({
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
        fade: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false
    });
};

/**
 * iPhoneX における高さのズレを修正
 */
const setHeaderDisplayArea = () => {
    const targets = $(".site-header .site-header__inner, .mainvisual, .mainvisual .slick-slider .slide-item")
        ;

    targets.each(function(){
        const target = $(this),
            win_height = $(window).height()
            ;

        target.css({
            "height": win_height + "px"
        });
    });
}

$(document).ready(function () {
	let ua = uajs()

	if(!ua.Mobile){
	    $('a[href^="tel:"]').addClass('reset-a-style').on('click', function(e) {
	        e.preventDefault();
	    });
	}

	if($(window).width() >= 720) {
		$('meta[name="viewport"]').attr('content', 'width=1360');
	}

	$("a[href^='#']").not(".no-scroll").click( function (e) {
		let href = $(this).attr('href');
		if(href.match(/^#/) && $(href).length > 0) {
			e.preventDefault();

			if ( $(".nav-main").is(":visible") ) {
                $(".nav-main").fadeOut(250);
                $(".button--toggle").removeClass("button--toggle--open");
            }

			$('html, body').animate({ scrollTop: $(href).offset().top }, 'fast');
		}
	});

	$('.button--toggle').click(function(e) {
		e.preventDefault()
		$(".button--toggle").toggleClass("button--toggle--open");
		$(".nav-main").fadeToggle(250)
	});

    setMainVisual();
    setMenuGallery();
    setHeaderDisplayArea();
});
